<template>
  <Funnel
    v-if="customChartData"
    :data="customChartData"
    :options="customChartOptions"
    :plugins="$options.plugins"
  />
</template>

<script>
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale
} from 'chart.js'
import { createTypedChart } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { FunnelController, TrapezoidElement } from 'chartjs-chart-funnel'
import chartMixin from '~/mixins/chartMixin'
import chartOptions from '~/assets/chart/options'
import { legendSpacing } from '~/assets/chart/plugins'
import exportedColors from '~/assets/style/_exportDefaultChartColors.module.scss'
import { formatMemberValue } from '~/services/analyticsFormat'
import { FormatContext, MemberFormat } from '~/types/analyticsFormat'

const LEGEND_INCREASE_WIDTH = 0
const LEGEND_INCREASE_MARGIN_LEFT = 24
const LEGEND_INCREASE_MARGIN_TOP = 16

const LegendSpacing = legendSpacing(
  LEGEND_INCREASE_WIDTH,
  0,
  LEGEND_INCREASE_MARGIN_LEFT,
  LEGEND_INCREASE_MARGIN_TOP
)

ChartJS.register(Tooltip, Legend, TrapezoidElement, CategoryScale, LinearScale)

const Funnel = createTypedChart('funnel', FunnelController)

export default {
  name: 'TrendVerticalFunnel',
  colors: Object.values(exportedColors),
  components: { Funnel },
  mixins: [chartMixin],
  plugins: [LegendSpacing, ChartDataLabels],
  methods: {
    generateCustomChartData(chartData) {
      const colors = this.$options.colors

      const valueOnChartLabelData = chartData.datasets.reduce(
        (data, dataset) => {
          data.push(
            formatMemberValue(
              dataset.data[0],
              MemberFormat.NUMBER_0,
              FormatContext.CHART,
              false
            )
          )
          return data
        },
        []
      )

      const deformedValueForChartData = chartData.datasets.map(
        (dataset, index) => {
          // Reorganize chartData to fit one dataset/multiple values chartjs-funnel requirements
          // Deform chartData to render visible labels and chart
          if (index === 0) {
            return chartData.datasets[1].data[0] * 1.3
          }
          return dataset.data[0] + chartData.datasets[1].data[0] / 10
        }
      )

      const rateOnChartLabelData = chartData.datasets.map(dataset => {
        return formatMemberValue(
          (dataset.data[0] / chartData.datasets[0].data[0]) * 100,
          MemberFormat.PERCENT_0,
          FormatContext.CHART,
          false
        )
      })

      const labels = chartData.datasets.reduce((data, dataset) => {
        data.push(dataset.label)
        return data
      }, [])

      const customChartData = {
        labels,
        datasets: [
          {
            data: deformedValueForChartData,
            valueOnChartLabelData,
            rateOnChartLabelData,
            backgroundColor: colors,
            borderColor: colors,
            datalabels: {
              labels: {
                onChartLabel: {
                  color: '#FFFFFF',
                  font: {
                    size: 13
                  },
                  anchor: 'start',
                  offset: -35,
                  formatter: (_, context) => {
                    return `${
                      context.dataset.valueOnChartLabelData[context.dataIndex]
                    } (${
                      context.dataset.rateOnChartLabelData[context.dataIndex]
                    })`
                  }
                }
              }
            }
          }
        ]
      }

      this.customChartData = customChartData
    },
    resetCustomChartOptions() {
      this.customChartOptions = {
        ...chartOptions,
        indexAxis: 'y',
        scales: this.getHiddenScales(true),
        plugins: {
          legend: {
            display: true,
            position: 'right',
            labels: {
              font: {
                size: 13
              },
              usePointStyle: true,
              generateLabels: chart => {
                return chart.data.labels.map((label, index) => ({
                  text: label,
                  fillStyle: chart.data.datasets[0].backgroundColor[index],
                  strokeStyle: chart.data.datasets[0].backgroundColor[index],
                  pointStyle: 'rectRounded'
                }))
              }
            }
          },
          tooltip: {
            ...chartOptions.plugins.tooltip,
            enabled: true,
            callbacks: {
              label: context => {
                return `${
                  context.dataset.valueOnChartLabelData[context.dataIndex]
                }`
              }
            }
          }
        }
      }
    }
  }
}
</script>
