<template>
  <Bar
    v-if="customChartData"
    :data="customChartData"
    :options="customChartOptions"
    :plugins="$options.plugins"
  />
</template>

<script>
import 'chart.js/auto'
import { Bar } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import chartMixin from '~/mixins/chartMixin'
import chartDrilldownMixin from '~/mixins/chartDrilldownMixin'
import chartOptions from '~/assets/chart/options'
import { formatMemberValue } from '~/services/analyticsFormat'
import { compareValuesAlphabetically } from '~/services/analytics'
import { FormatContext } from '~/types/analyticsFormat'

const BORDER_RADIUS = 4

export default {
  name: 'ChartBar',
  components: { Bar },
  mixins: [chartMixin, chartDrilldownMixin],
  plugins: [ChartDataLabels],
  props: {
    colors: {
      type: Array,
      default: () => []
    },
    showLegend: {
      type: Boolean,
      default: false
    },
    isStacked: {
      type: Boolean,
      default: false
    },
    isTimeQuery: {
      type: Boolean,
      default: false
    },
    showDatalabels: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    showTicks: {
      type: Boolean,
      default: true
    },
    showTooltip: {
      type: Boolean,
      default: true
    },
    isMultiMeasures: {
      type: Boolean,
      default: false
    },
    legendPosition: {
      type: String,
      default: 'top'
    }
  },
  methods: {
    generateCustomChartData(chartData) {
      const length = chartData.datasets.length
      const colors = this.getColorGradientPalette(length)

      const groupKeys = chartData.datasets.reduce((acc, dataset) => {
        if (dataset.groupKey !== undefined && dataset.groupKey !== null) {
          acc.push(dataset.groupKey)
        }
        return acc
      }, [])

      const customChartData = {
        ...chartData,
        labels: chartData.labels.map(label => label.formattedValue),
        datasets: chartData.datasets
          .map((dataset, i) => {
            const j =
              this.isMultiMeasures && this.isStacked
                ? groupKeys.indexOf(dataset.groupKey)
                : i
            const color = colors[j % colors.length]

            return {
              ...dataset,
              yAxisID: length === 1 ? 'y' : `y${dataset.stackKey}`,
              backgroundColor: context => {
                if (this.isStacked) {
                  if (this.isTimeQuery) {
                    // add transparency if in future
                    return chartData.labels.map(label =>
                      label.isInFuture
                        ? color.bottom + this.$options.colorTransparencySuffix
                        : color.bottom
                    )
                  }
                  return color.bottom
                } else {
                  const chart = context.chart
                  const { ctx, chartArea } = chart
                  if (!chartArea) {
                    return
                  }
                  this.generateGradientColor(ctx, chartArea, i, color)
                  if (this.isTimeQuery) {
                    // generate transparent color
                    this.generateGradientColor(
                      ctx,
                      chartArea,
                      i + length,
                      color,
                      true
                    )
                    // add transparency if in future
                    return chartData.labels.map(label =>
                      label.isInFuture
                        ? this.chartGradients[i + length]
                        : this.chartGradients[i]
                    )
                  }
                  return this.chartGradients[i]
                }
              },
              borderRadius:
                this.isStacked && this.isMultiMeasures
                  ? { topLeft: 0, topRight: 0, bottomLeft: 0, bottomRight: 0 }
                  : {
                      topLeft:
                        !this.isStacked ||
                        i === length - 1 ||
                        (this.isMultiMeasures && i === length % 2)
                          ? BORDER_RADIUS
                          : 0,
                      topRight:
                        !this.isStacked ||
                        i === length - 1 ||
                        (this.isMultiMeasures && i === length % 2)
                          ? BORDER_RADIUS
                          : 0,
                      bottomLeft:
                        !this.isStacked ||
                        i === 0 ||
                        (this.isMultiMeasures && i === length / 2 + 1)
                          ? BORDER_RADIUS
                          : 0,
                      bottomRight:
                        !this.isStacked ||
                        i === 0 ||
                        (this.isMultiMeasures && i === length / 2 + 1)
                          ? BORDER_RADIUS
                          : 0
                    },
              hoverBorderColor: '#141644',
              hoverBorderWidth: 4,
              borderSkipped: false
            }
          })
          .sort((a, b) => {
            // sort by groupKey and stackKey to allow keeping only one label out of two
            if (a.groupKey && b.groupKey && this.isMultiMeasures) {
              return a.groupKey === b.groupKey
                ? compareValuesAlphabetically(
                    String(a.stackKey),
                    String(b.stackKey)
                  )
                : compareValuesAlphabetically(
                    String(a.groupKey),
                    String(b.groupKey)
                  )
            }
            return 0
          })
      }

      this.removeZeroInData(customChartData)

      this.customChartData = customChartData
    },
    resetCustomChartOptions(newValue) {
      const yScales = this.compileYScales(newValue.datasets)
      this.customChartOptions = {
        ...chartOptions,
        onHover: (event, chartElement) => {
          event.native.target.style.cursor = chartElement[0]
            ? 'pointer'
            : 'default'
        },
        onClick: clickEvent => {
          this.openDrilldownFromElementClickEvent &&
            this.openDrilldownFromElementClickEvent(clickEvent)
        },
        scales: {
          ...yScales,
          x: {
            ...chartOptions.scales.x,
            stacked: this.isStacked,
            ticks: {
              ...chartOptions.scales.x.ticks,
              display: this.showTicks,
              font: {
                size: this.large ? 14 : 13
              }
            }
          }
        },
        plugins: {
          legend: {
            display: this.showLegend,
            position: this.legendPosition,
            labels: {
              font: {
                size: this.large ? 14 : 13
              },
              pointStyle: 'rectRounded',
              usePointStyle: true,
              filter: item => {
                return !(
                  this.isMultiMeasures &&
                  this.isStacked &&
                  item.datasetIndex % 2 === 1
                )
              }
            }
          },
          tooltip: {
            ...chartOptions.plugins.tooltip,
            enabled: this.showTooltip,
            callbacks: {
              title: context => {
                const xLabel = context[0].label
                const stackLabel = context[0].dataset.label
                return this.isStacked ? `${xLabel} - ${stackLabel}` : xLabel
              },
              label: context => {
                const label = context.dataset.stack
                const format = context.dataset.format
                const value = context.parsed.y
                return `${label}: ${formatMemberValue(
                  value,
                  format,
                  FormatContext.CHART,
                  false
                )}`
              }
            }
          },
          datalabels: {
            ...chartOptions.plugins.datalabels,
            color: this.showDatalabels ? '#FFFFFF' : 'transparent',
            formatter: (value, context) => {
              const format = context.dataset.format
              return formatMemberValue(
                value,
                format,
                FormatContext.CHART,
                false
              )
            }
          }
        }
      }
    },
    compileYScales(datasets) {
      if (datasets.length === 1) {
        return {
          y: this.compileYScaleOptions(datasets[0], 0, true)
        }
      } else {
        return datasets.reduce(
          (acc, value, i) => ({
            ...acc,
            [`y${value.stackKey}`]: this.compileYScaleOptions(value, i, true)
          }),
          {}
        )
      }
    },
    compileYScaleOptions(dataset, i, beginAtZero) {
      return {
        ...chartOptions.scales.y,
        type: 'linear',
        position:
          this.chartData.labels.length > 14
            ? 'left'
            : this.isMultiMeasures && i % 2 === 1
            ? 'right'
            : 'left',
        stack: dataset.stackKey,
        stacked: this.isStacked,
        beginAtZero,
        ticks: {
          ...chartOptions.scales.y.ticks,
          display: this.showTicks,
          font: {
            size: this.large ? 14 : 13
          },
          maxTicksLimit: this.large ? 8 : 5,
          callback: value => {
            return formatMemberValue(
              value,
              dataset.format,
              FormatContext.CHART,
              false
            )
          }
        }
      }
    },
    getColorGradientPalette(datasetsLength) {
      const colors = []
      for (let i = 0; i < datasetsLength; i++) {
        const j = i % (this.colors.length - 1)
        colors.push({
          bottom: this.colors[j],
          top: this.colors[j + 1]
        })
      }
      return colors
    }
  }
}
</script>
