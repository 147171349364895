import {
  type Measure,
  type Dimension,
  FilterOperator,
  MeasureCategory,
  DimensionCategory,
  OrderValue
} from '~/types/cube'
import { MemberFormat } from '~/types/analyticsFormat'
import type { EXPLORER_TYPE } from '~/constants/renderTypes'
import type { ALL_DATA_TYPE } from '~/constants/analytics'
import { SourceType } from '~/types/sources'

export interface Stat {
  evolution?: number | null
  value: number | null
}

export interface Filter {
  member: string
  operator: FilterOperator
  values: string[]
}

export interface BenchmarkSegment {
  title: string
  value: string
}

export interface BenchmarkQuery {
  measureTitle: string
  startDate: string
  endDate: string
  segments: BenchmarkSegment[]
}

export interface BenchmarkValue {
  lowerBound: number
  upperBound: number
  probability: number
}

export interface BenchmarkQueryResult {
  trustLevel: number
  sampleSize: number
  analyticsValue: number
  values: BenchmarkValue[]
}

export enum DataType {
  EMPLOYEE = 'EMPLOYEE',
  RECRUITMENT = 'RECRUITMENT'
}

export type DataTypeOrAll = DataType | typeof ALL_DATA_TYPE

export interface ScopeFilter extends Filter {
  dataType: DataType
}

export interface Segment {
  segmentName: string
  segmentShortTitle: string
  options: string[]
}

export type MemberCategory = MeasureCategory | DimensionCategory

export type MeasureOption = Measure & {
  label: string
  defaultLabel: string
}

export type MeasureOptionWithCategory = MeasureOption & {
  dataType: DataType
  category: MeasureCategory
}

export type DimensionOption = Dimension & {
  label: string
  defaultLabel: string
}

export type DimensionOptionWithCategory = DimensionOption & {
  dataType: DataType | null
  category: DimensionCategory
}

export type MeasureOptionsCategory = {
  id: MeasureCategory
  dataType: DataType
  options: MeasureOption[]
}

export type DimensionOptionsCategory = {
  id: DimensionCategory
  dataType: DataType | null
  options: DimensionOption[]
}

export type MemberOptions = DimensionOption[] | MeasureOption[]

export type MemberOptionsWithCategory =
  | MeasureOptionWithCategory[]
  | DimensionOptionWithCategory[]

export type MemberOptionsCategories =
  | MeasureOptionsCategory[]
  | DimensionOptionsCategory[]

export interface ExplorerTableColumn {
  shortTitle: string
  name: string
  value: string
  format: MemberFormat
  type?: string
}

export interface SortingByColumn {
  [key: string]: OrderValue | null
}

export type DimensionValue = string | null

export type DimensionWithValue = [dimension: Dimension, value: DimensionValue]

export interface ExplorerDrilldownOptions {
  originalMeasureTitle: string
  measure: Measure
  dimensionsWithValue: DimensionWithValue[]
}

export interface ChartDataSerie {
  key: string
  label: string
  data: number[]
  format: string
}

export interface ChartData {
  labels: string[]
  datasets: ChartDataSerie[]
  isInFuture?: boolean[]
}

export interface ModuleExplorerLink {
  measure: string
  type: EXPLORER_TYPE
  dimension?: string
  group?: string
  sort?: boolean
}

export interface ModuleBenchmarkLink {
  measure: string
}

export interface ModuleInfoContent {
  source: SourceType
  definition: string
  boldDefinition?: string
  insight?: string
  calculation?: {
    legends: string[]
    formula: string
  }
}

export enum MemberType {
  DIMENSION = 'DIMENSION',
  MEASURE = 'MEASURE',
  GROUP = 'GROUP'
}

export enum DimensionSelectionContext {
  LIST = 'LIST',
  GENERIC = 'GENERIC',
  BDESE_GENERIC = 'BDESE_GENERIC',
  FILTERS = 'FILTERS',
  BDESE_FILTERS = 'BDESE_FILTERS',
  USER_SCOPE_FILTERS = 'USER_SCOPE_FILTERS',
  BDESE_SCOPE_FILTERS = 'BDESE_SCOPE_FILTERS'
}

export enum MeasureSelectionContext {
  LIST = 'LIST',
  BENCHMARK = 'BENCHMARK',
  GENERIC = 'GENERIC',
  BDESE_GENERIC = 'BDESE_GENERIC'
}

export type StandardFilterOption = {
  value: string | number
  label: string
}

export enum QueryTimeType {
  PERIOD_WITH_COMPARISON = 'PERIOD_WITH_COMPARISON',
  PERIOD_WITH_TREND_GRAIN = 'PERIOD_WITH_TREND_GRAIN',
  MULTI_YEAR_PERIOD = 'MULTI_YEAR_PERIOD',
  PERIOD = 'PERIOD',
  CUSTOM_PERIOD = 'CUSTOM_PERIOD',
  NO_PERIOD = 'NO_PERIOD'
}

export type QueryTimeOption =
  | {
      type: Exclude<QueryTimeType, QueryTimeType.CUSTOM_PERIOD>
    }
  | {
      type: QueryTimeType.CUSTOM_PERIOD
      dateRange: [string, string]
    }
