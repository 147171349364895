<template>
  <div>
    <button
      class="help-button"
      @click="event => openHelpMenu(event)"
    >
      <i class="help-button__info-icon icon-info" />
    </button>
    <Teleport
      v-if="isHelpMenuOpen"
      to="#action"
    >
      <ReflectOverlay @cancel="isHelpMenuOpen = false">
        <div
          class="help-menu"
          :style="{
            bottom: `calc(100% - ${helpMenuPosition!.y}px)`,
            left: helpMenuPosition!.x +'px'
          }"
        >
          <div class="help-menu__section">
            <button
              class="section__button"
              @click="openLink(HELP_MENU_LINKS.helpCenter!)"
            >
              <i class="button__icon icon-book" />
              <p class="button__title">{{ $t('menu.helpMenu.helpCenter') }}</p>
              <i class="button__icon icon-open-external-2" />
            </button>
            <button
              class="section__button"
              @click="openIntercomMessenger"
            >
              <i class="button__icon icon-messenger" />
              <p class="button__title">
                {{ $t('menu.helpMenu.contactSupport') }}
              </p>
            </button>
          </div>
          <div class="help-menu__section">
            <button
              class="section__button"
              @click="openIntercomMessenger"
            >
              <i class="button__icon icon-light-bulb" />
              <p class="button__title">
                {{ $t('menu.helpMenu.askForANewFeature') }}
              </p>
            </button>
          </div>
          <div class="help-menu__section">
            <button
              class="section__button"
              @click="openLink(HELP_MENU_LINKS.otherResources!)"
            >
              <p class="button__title button__title--without-header-icon">
                {{ $t('menu.helpMenu.otherResources') }}
              </p>
              <i
                class="button__icon icon-open-external-2 button__icon--without-header-icon"
              />
            </button>
            <button
              class="section__button"
              @click="openLink(HELP_MENU_LINKS.reflectCommunity!)"
            >
              <p class="button__title button__title--without-header-icon">
                {{ $t('menu.helpMenu.reflectCommunity') }}
              </p>
              <i
                class="button__icon icon-open-external-2 button__icon--without-header-icon"
              />
            </button>
            <button
              class="section__button"
              @click="openLink(HELP_MENU_LINKS.showcaseWebsite!)"
            >
              <p class="button__title button__title--without-header-icon">
                {{ $t('menu.helpMenu.showcaseWebsite') }}
              </p>
              <i
                class="button__icon icon-open-external-2 button__icon--without-header-icon"
              />
            </button>
          </div>
        </div>
      </ReflectOverlay>
    </Teleport>
  </div>
</template>

<script lang="ts">
import { useGtm } from '@gtm-support/vue-gtm'
import { getAppLocale } from '~/services/locale'
import { AvailableLocale } from '~/types/locale'

type i18nLinks = { [key in AvailableLocale]: string }

const HELP_MENU_LINKS: { [key: string]: i18nLinks } = {
  helpCenter: {
    [AvailableLocale.EN]: 'https://help.getreflect.io/en',
    [AvailableLocale.FR]: 'https://help.getreflect.io'
  },
  otherResources: {
    [AvailableLocale.EN]: 'https://www.getreflect.io/blog',
    [AvailableLocale.FR]: 'https://www.getreflect.io/blog'
  },
  reflectCommunity: {
    [AvailableLocale.EN]:
      'https://join.slack.com/t/reflectcommunity/shared_invite/zt-2vk6sv8vk-59LLRjqsb_0cVty~6cRo8A',
    [AvailableLocale.FR]:
      'https://join.slack.com/t/reflectcommunity/shared_invite/zt-2vk6sv8vk-59LLRjqsb_0cVty~6cRo8A'
  },
  showcaseWebsite: {
    [AvailableLocale.EN]: 'https://www.getreflect.io/',
    [AvailableLocale.FR]: 'https://www.getreflect.io/'
  }
}

export default {
  name: 'HelpMenu',
  setup() {
    return {
      HELP_MENU_LINKS
    }
  },
  data(): {
    isHelpMenuOpen: boolean
    helpMenuPosition: { x: number; y: number } | undefined
  } {
    return {
      isHelpMenuOpen: false,
      helpMenuPosition: undefined
    }
  },
  methods: {
    openLink(links: i18nLinks) {
      const link =
        links[getAppLocale() as AvailableLocale] || links[AvailableLocale.FR]

      window.open(link, '_blank')
    },
    openHelpMenu(event: Event) {
      const position = (
        event.currentTarget as HTMLDivElement
      ).getBoundingClientRect()

      this.helpMenuPosition = { x: position.left, y: position.y }

      this.isHelpMenuOpen = true
    },
    openIntercomMessenger() {
      const gtm = useGtm()
      // send custom event to dataLayer which (from gtm - google tag manager) trigger Intercom tag opening messenger
      gtm?.trackEvent({ event: 'intercom-messenger-launch-requested' })
    }
  }
}
</script>

<style lang="scss" scoped>
.help-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px $border-primary;
  border-radius: 12px;
  height: 24px;
  width: 24px;
  background-color: $bg-new-secondary;
  border-color: $border-new-ternary;

  &__info-icon {
    @include font-icon;
    @include font-size(16px);
    color: $text-primary;
  }

  &:hover {
    cursor: pointer;
  }
}

.help-menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: $bg-secondary;
  border: solid 1px $border-new-ternary;
  border-radius: 8px;
  margin-bottom: $margin-mini;

  &__section {
    border-bottom: solid 1px $border-new-ternary;
    padding: $margin-small $margin-mini;

    &:last-child {
      border-bottom: none;
    }

    .section {
      &__button {
        display: flex;
        width: 100%;
        align-items: center;
        @include font-text($font-weight-book);
        padding: $margin-tiny;
        cursor: pointer;

        &:hover {
          background-color: $bg-new-quaternary;
          border-radius: 4px;
        }

        .button {
          &__icon {
            @include font-icon;

            &--without-header-icon {
              color: $text-new-tertiary;
            }
          }

          &__title {
            margin-left: $margin-mini;

            &--without-header-icon {
              margin-left: 0;
              color: $text-new-tertiary;
            }
          }
        }
      }
    }
  }
}
</style>
