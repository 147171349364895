<template>
  <Bar
    :data="customChartData"
    :options="customChartOptions"
    class="chart-container"
  />
</template>

<script>
import 'chart.js/auto'
import { Bar } from 'vue-chartjs'
import chartOptions from '~/assets/chart/options'
import chartMixin from '~/mixins/chartMixin'
import benchmarkColorMixin from '~/mixins/benchmarkColorMixin'
import exportedColors from '~/assets/style/_exportBenchmarkChartColors.module.scss'
import { formatMemberValue } from '~/services/analyticsFormat'
import { FormatContext } from '~/types/analyticsFormat'

const TRANSPARENCY_SUFFIX_70 = 'B3'

export default {
  name: 'ChartBenchmark',
  components: { Bar },
  mixins: [chartMixin, benchmarkColorMixin],
  props: {
    labelFormat: {
      type: String,
      default: 'string'
    },
    measureBenchmarkIndex: {
      type: Number,
      default: 0
    },
    measureTier: {
      type: Number,
      default: 1
    },
    isFavorable: {
      type: Boolean,
      default: undefined
    }
  },
  computed: {
    colorGradientPalette() {
      return {
        standard: {
          top: exportedColors.colorbenchmarkstandard,
          bottom: exportedColors.colorbenchmarkstandard + TRANSPARENCY_SUFFIX_70
        },
        favorable: exportedColors.colorbenchmarkfavorable,
        'in-average': exportedColors.colorbenchmarkinaverage,
        unfavorable: exportedColors.colorbenchmarkunfavorable,
        neutral: exportedColors.colorbenchmarkneutral
      }
    }
  },
  methods: {
    generateCustomChartData(chartData) {
      this.customChartData = {
        ...chartData,
        datasets: chartData.datasets.map(dataset => ({
          ...dataset,
          backgroundColor: context => {
            if (context.dataIndex === this.measureBenchmarkIndex) {
              return this.colorGradientPalette[
                this.getColorKeyFromMeasureFavorabilityAndTier(
                  this.isFavorable,
                  this.measureTier
                )
              ]
            }
            const chart = context.chart
            const { ctx, chartArea } = chart
            const color = this.colorGradientPalette.standard
            if (!chartArea) {
              return
            }
            this.generateGradientColor(ctx, chartArea, context.index, color)
            return this.chartGradients[context.index]
          }
        }))
      }
    },
    resetCustomChartOptions() {
      const { labelFormat } = this
      this.customChartOptions = {
        ...chartOptions,
        elements: {
          bar: {
            borderWidth: 0,
            borderRadius: 8
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            ...chartOptions.plugins.tooltip,
            callbacks: {
              title: context => {
                return context[0].chart.config._config.data.datasets[0].label
              },
              label: context => {
                const label =
                  context.chart.config._config.data.labels[context.dataIndex]
                return `${formatMemberValue(
                  label.lowerBound,
                  labelFormat,
                  FormatContext.CHART,
                  false
                )} - ${formatMemberValue(
                  label.upperBound,
                  labelFormat,
                  FormatContext.CHART,
                  false
                )}`
              }
            }
          }
        },
        scales: {
          y: {
            display: false
          },
          x: {
            grid: {
              display: false
            },
            border: {
              display: false
            },
            ticks: {
              display: true,
              callback(val, index) {
                // Hide every 2nd tick label
                const { upperBound, lowerBound } = this.getLabelForValue(val)
                const labelValue = (upperBound + lowerBound) / 2
                return index % 2 === 0
                  ? formatMemberValue(
                      labelValue,
                      labelFormat,
                      FormatContext.CHART,
                      false
                    )
                  : ''
              }
            }
          }
        }
      }
    }
  }
}
</script>

<style>
.chart-container {
  height: 100%;
  width: 100%;
}
</style>
