import { gql } from 'graphql-tag'

export const FETCH_CURRENT_USER_QUERY = gql`
  query FETCH_CURRENT_USER_QUERY {
    me {
      id
      company {
        id
        domains
        name
      }
      scope {
        filters {
          member
          dataType
          operator
          values
        }
      }
      email
      name
      avatarUrl
      status
      role
      isServiceUser
      homeModules
      sourcePermissions {
        sourceAccessType
        source {
          id
        }
      }
      intercomHash
    }
  }
`
