import { mergeAttributes, Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'
import Component from './EditorAttachmentZone.vue'
import {
  ATTACHMENT_ZONE_ATTRIBUTES,
  ATTACHMENT_ZONE_NODE_NAME
} from '~/constants/bdese'

export default Node.create({
  name: ATTACHMENT_ZONE_NODE_NAME,

  group: 'block',

  atom: true,

  addAttributes() {
    return {
      [ATTACHMENT_ZONE_ATTRIBUTES.ATTACHMENT_IDS]: {
        default: []
      },
      [ATTACHMENT_ZONE_ATTRIBUTES.UPDATED_AT]: {
        default: null
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'attachment-zone'
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['attachment-zone', mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return VueNodeViewRenderer(Component)
  }
})
