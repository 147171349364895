<template>
  <div
    v-if="resultSet"
    class="top-renderer"
  >
    <table
      v-if="!isEmpty"
      class="table"
    >
      <thead class="table__header">
        <tr class="header__row">
          <th
            v-for="(header, columnIndex) in headers"
            :key="header.name"
            class="header__value"
            :class="columnIndex === 0 && 'header__value--is-first-column'"
          >
            {{ header.value }}
          </th>
        </tr>
      </thead>
      <tbody class="table__body">
        <tr
          v-for="(item, i) in items"
          :key="i"
          class="body__row"
        >
          <td
            v-for="(header, columnIndex) in headers"
            :key="header.name"
            class="value-container"
          >
            <div
              class="value-container__value"
              :class="
                columnIndex === 0 && 'value-container__value--is-first-column'
              "
            >
              <img
                v-if="header.format === MemberFormat.NAME"
                src="~/assets/images/avatar.png"
                draggable="false"
                class="value__user-icon"
              />
              {{
                formatMemberValue(
                  item[header.name]!,
                  header.format!,
                  FormatContext.DEFAULT,
                  false
                )
              }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-else
      class="top-renderer__empty"
    >
      <i class="empty__icon icon-error" />
      <p class="empty__text">
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { getMembersFromNames } from '~/services/explore'
import { formatMemberValue } from '~/services/analyticsFormat'
import { FormatContext, MemberFormat } from '~/types/analyticsFormat'
import type { ResultSet } from '~/types/query'

export default {
  name: 'RendererTop',
  props: {
    resultSet: {
      type: Object as PropType<ResultSet>,
      required: true
    },
    columnOrder: {
      type: Array,
      default: () => []
    },
    topSize: {
      type: Number,
      default: 5
    },
    columnTranslationPrefix: {
      type: String,
      required: true
    }
  },
  setup() {
    return { MemberFormat, formatMemberValue, FormatContext }
  },
  computed: {
    headers() {
      const columnsNames = Object.keys(this.resultSet.results[0]!.data[0]!)
      const columns = getMembersFromNames(columnsNames)

      return this.columnOrder.map(orderedColumn => {
        const column = columns.find(
          column => column.shortTitle === orderedColumn
        )!

        return {
          name: column.name,
          value: this.$t(
            this.columnTranslationPrefix + '.' + column.shortTitle
          ),
          format: column.meta ? column.meta.format : undefined,
          type: column.type
        }
      })
    },
    items() {
      return this.resultSet.results[0]!.data.slice(0, this.topSize)
    },
    isEmpty() {
      return this.items.length === 0
    },
    errorMessage() {
      if (this.isEmpty) return this.$t('dashboard.error.empty.table')
      return this.$t('dashboard.error.unknown')
    }
  }
}
</script>

<style lang="scss" scoped>
.top-renderer {
  &__title {
    @include font-title;
    @include font-size($font-size-large);
    color: $text-primary;
  }

  &__empty {
    width: 160px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    .empty {
      &__icon {
        @include font-icon;
        @include font-size(24px);
        color: $text-secondary;
        margin-bottom: 4px;
      }

      &__text {
        @include font-text;
        @include font-size($font-size-mini);
        color: $text-secondary;
        text-align: center;
        margin-bottom: 24px;
      }
    }
  }

  .table {
    width: 100%;
    table-layout: fixed;
    margin-top: $margin-small;

    &__header {
      .header {
        &__row {
          .row {
            &__value {
              @include font-text;
              @include font-size($font-size-mini);
              color: $text-primary;
            }
          }
        }

        &__value {
          @include font-text;
          @include font-size($font-size-mini);
          color: $text-primary;
          text-align: center;
          vertical-align: middle;
          padding: 6px 2px;

          &--is-first-column {
            color: $text-primary;
            width: 50%;
            text-align: left;
          }
        }
      }
    }

    &__body {
      .body {
        &__row {
          width: 100%;
        }
      }
    }
  }

  .value-container {
    @include font-text;
    @include font-size($font-size-mini);
    color: $text-tertiary;
    vertical-align: middle;
    padding: 6px 2px;

    &__value {
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &--is-first-column {
        display: flex;
        align-items: center;
        color: $text-primary;
        text-align: left;
      }

      .value {
        &__user-icon {
          display: inline;
          height: 24px;
          width: 24px;
          margin-right: $margin-mini;
        }
      }
    }
  }
}
</style>
