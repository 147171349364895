<template>
  <Funnel
    v-if="customChartData"
    :data="customChartData"
    :options="customChartOptions"
    :plugins="$options.plugins"
  />
</template>

<script>
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale
} from 'chart.js'
import { createTypedChart } from 'vue-chartjs'
import { FunnelController, TrapezoidElement } from 'chartjs-chart-funnel'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import chartMixin from '~/mixins/chartMixin'
import chartOptions from '~/assets/chart/options'
import { legendSpacing } from '~/assets/chart/plugins'
import exportedColors from '~/assets/style/_exportDefaultChartColors.module.scss'
import { formatMemberValue } from '~/services/analyticsFormat'
import { FormatContext, MemberFormat } from '~/types/analyticsFormat'

const LEGEND_INCREASE_WIDTH = 16
const LegendSpacing = legendSpacing(LEGEND_INCREASE_WIDTH)

ChartJS.register(Tooltip, Legend, TrapezoidElement, CategoryScale, LinearScale)

const Funnel = createTypedChart('funnel', FunnelController)

export default {
  name: 'ChartHorizontalFunnel',
  components: { Funnel },
  mixins: [chartMixin],
  props: {
    legendPosition: {
      type: String,
      default: 'right'
    }
  },
  colors: Object.values(exportedColors),
  plugins: [LegendSpacing, ChartDataLabels],
  methods: {
    generateCustomChartData(chartData) {
      const colors = this.$options.colors

      const valueOnChartLabelData = chartData.datasets.reduce(
        (data, dataset) => {
          data.push(dataset.data[0])
          return data
        },
        []
      )

      const deformedValueForChartData = chartData.datasets.map(
        (dataset, index) => {
          // Reorganize chartData to fit one dataset/multiple values chartjs-funnel requirements
          // Deform chartData to render visible labels and chart
          if (index === 0) {
            return chartData.datasets[1].data[0] * 1.3
          }
          return dataset.data[0] + chartData.datasets[1].data[0] / 10
        }
      )

      const rateOnChartLabelData = chartData.datasets.map(dataset => {
        return formatMemberValue(
          (dataset.data[0] / chartData.datasets[0].data[0]) * 100,
          MemberFormat.PERCENT_0,
          FormatContext.CHART,
          false
        )
      })

      const rateOnSideLabelData = chartData.datasets.map((dataset, index) => {
        return formatMemberValue(
          index === 0
            ? 1
            : (dataset.data[0] / chartData.datasets[index - 1].data[0]) * 100,
          MemberFormat.PERCENT_0,
          FormatContext.CHART,
          false
        )
      })

      const labels = chartData.datasets.reduce((data, dataset) => {
        data.push(dataset.label)
        return data
      }, [])

      const customChartData = {
        labels,
        datasets: [
          {
            data: deformedValueForChartData,
            backgroundColor: colors,
            borderColor: colors,
            valueOnChartLabelData,
            rateOnChartLabelData,
            rateOnSideLabelData,
            datalabels: {
              labels: {
                onChartLabel: {
                  color: 'white',
                  font: {
                    size: 13
                  },
                  anchor: 'start',
                  offset: -20,
                  formatter: (_, context) => {
                    return `${
                      context.dataset.valueOnChartLabelData[context.dataIndex]
                    }\n(${
                      context.dataset.rateOnChartLabelData[context.dataIndex]
                    })`
                  }
                },
                onSideLabel: {
                  anchor: 'start',
                  align: 285,
                  offset: -190,
                  backgroundColor: '#F2F5FA',
                  borderRadius: 10,
                  color: '#1E1E3C',
                  font: {
                    size: 14
                  },
                  display: context => {
                    return context.dataIndex !== 0
                  },

                  formatter: (_, context) => {
                    return `→ ${
                      context.dataset.rateOnSideLabelData[context.dataIndex]
                    }`
                  }
                }
              }
            }
          }
        ]
      }

      this.customChartData = customChartData
    },

    resetCustomChartOptions() {
      this.customChartOptions = {
        ...chartOptions,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 32,
            bottom: 8
          }
        },
        indexAxis: 'x',
        scales: this.getHiddenScales(true),
        plugins: {
          legend: {
            display: true,
            position: this.legendPosition,
            labels: {
              font: {
                size: 13
              },
              usePointStyle: true,
              generateLabels: chart => {
                return chart.data.labels.map((label, index) => ({
                  text: label,
                  hidden: false,
                  fillStyle: chart.data.datasets[0].backgroundColor[index],
                  strokeStyle: chart.data.datasets[0].backgroundColor[index],
                  pointStyle: 'rectRounded'
                }))
              }
            }
          },
          tooltip: {
            ...chartOptions.plugins.tooltip,
            enabled: true,
            callbacks: {
              label: context => {
                return `${
                  context.dataset.valueOnChartLabelData[context.dataIndex]
                }`
              }
            }
          }
        }
      }
    }
  }
}
</script>
