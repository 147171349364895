import { useGtm } from '@gtm-support/vue-gtm'
import { type CurrentUser } from '~/types/user'
import { FETCH_CURRENT_USER_QUERY } from '~/gql/users/fetchCurrentUserQuery'
import { UPDATE_USER_HOME_MODULES_MUTATION } from '~/gql/users/updateUserHomeModulesMutation'
import { Dashboard } from '~/constants/homeModules'
import { UserRole } from '~/types/permissions'
import { SourceAccessType } from '~/types/sources'

const BENCHMARK_UNAUTHORIZED_NAMES = ['sezane.com']

type State = CurrentUser

const EMPTY_USER = {
  id: '',
  email: '',
  name: '',
  avatarUrl: '',
  status: '',
  role: UserRole.NONE,
  permissions: [],
  company: null,
  scope: null,
  isServiceUser: null,
  homeModules: [],
  sourcePermissions: [],
  intercomHash: ''
}

export const useCurrentUserStore = defineStore('currentUser', {
  state: (): State => ({ ...EMPTY_USER }),
  getters: {
    canAccessBenchmark(state: State): boolean {
      if (!state.company) return false
      return !BENCHMARK_UNAUTHORIZED_NAMES.includes(state.company.name)
    },
    isCurrentUserAdmin(state: State): boolean {
      return state.role === UserRole.ADMIN
    },
    hasEditorSourcePermission(state: State): boolean {
      return (
        state.sourcePermissions.some(
          permission => permission.sourceAccessType === SourceAccessType.EDITOR
        ) || this.isCurrentUserAdmin
      )
    }
  },
  actions: {
    resetCurrentUser() {
      this.updateUser({ ...EMPTY_USER })
      const app = useNuxtApp()
      app.$apiGqlClient.clearStore() // Clear gql cache
    },
    async loadCurrentUser() {
      const app = useNuxtApp()

      const user = (
        await app.$apiGqlClient.query({
          query: FETCH_CURRENT_USER_QUERY
        })
      ).data.me as CurrentUser

      this.updateUser(user)

      const trackingData = {
        event: 'user-loaded',
        companyID: user.company ? user.company.id : null,
        companyName: user.company ? user.company.name : null,
        companyDomain: user.company ? user.company.domains[0] : null,
        email: user.email,
        userID: user.id,
        name: user.name,
        role: user.role,
        intercomHash: user.intercomHash
      }

      // Identify user for GTM and Intercom tracking
      const gtm = useGtm()
      gtm?.trackEvent(trackingData)

      // Identify user for June tracking
      app.$june.identify(user.id, trackingData)
    },
    updateUser(user: State) {
      this.id = user.id
      this.email = user.email
      this.name = user.name
      this.avatarUrl = user.avatarUrl
      this.status = user.status
      this.role = user.role
      this.permissions = user.permissions
      this.company = user.company
      this.scope = user.scope
      this.homeModules = user.homeModules
      this.sourcePermissions = user.sourcePermissions
      this.isServiceUser = user.isServiceUser
    },
    async updateUserHomeModules(newHomeModules: Dashboard[]) {
      const app = useNuxtApp()
      const userCurrentHomeModules = this.homeModules
      this.homeModules = newHomeModules
      try {
        await app.$apiGqlClient.mutate({
          mutation: UPDATE_USER_HOME_MODULES_MUTATION,
          variables: {
            homeModules: newHomeModules
          }
        })
      } catch (error) {
        this.homeModules = userCurrentHomeModules
        throw error
      }
    },
    async removeUserHomeModule(moduleIdToRemove: Dashboard) {
      const newHomeModules = this.homeModules.filter(
        moduleId => moduleId !== moduleIdToRemove
      )
      await this.updateUserHomeModules(newHomeModules)
    },
    async addUserHomeModule(moduleIdToAdd: Dashboard) {
      const newHomeModules = [...this.homeModules, moduleIdToAdd]
      await this.updateUserHomeModules(newHomeModules)
    }
  }
})
